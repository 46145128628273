import Form from "react-bootstrap/Form";
import Button from "features/tasks/form/Button";
import useSetPasswordScreen from "./useSetPasswordScreen";
import loaderStyles from "app/Loader.module.css";
import styles from "./SetPasswordScreen.module.scss";
import type { FormData } from "./useSetPasswordScreen";
import FormErrorMessage from "app/FormError";
import formStyles from "app/form.module.scss";

export default function SetPasswordScreen() {
    const {
        setPasswordState,
        errors,
        passwordErrorMessage,
        resetPasswordCredential,
        generatedPassword,
        isLoggedIn,
        profile,
        handleSubmit,
        register,
        setPassword,
        validateConfirm,
    } = useSetPasswordScreen();
    const onSubmit = (data: FormData) => {
        setPassword(data);
    };
    return (
        <div className={styles.SetPasswordScreen}>
            {!isLoggedIn && (
                <div className={styles.logoContainer}>
                    <img
                        src="/Chomp-Logo-simplified.png"
                        alt="Chomp"
                        className={styles.logo}
                    />
                </div>
            )}
            <p>
                {resetPasswordCredential &&
                    "To complete your password reset, please set your new password."}
                {generatedPassword &&
                    "To complete your registration, please set a new password."}
            </p>
            <p>
                Please enter your new password and confirmation so we can verify
                you typed it in correctly.
            </p>
            <Form onSubmit={handleSubmit(onSubmit)}>
                {!resetPasswordCredential && !generatedPassword && (
                    <Form.Group className={styles.group}>
                        <Form.Label>Current password</Form.Label>
                        <Form.Control
                            type="password"
                            defaultValue={""}
                            id="currentPassword"
                            {...register("current", { required: true })}
                        />
                        <FormErrorMessage error={errors.current} />
                    </Form.Group>
                )}

                <Form.Group className={styles.group}>
                    <Form.Label>New password</Form.Label>
                    <Form.Control
                        type="password"
                        defaultValue={""}
                        id="newPassword"
                        autoComplete="off"
                        minLength={9}
                        {...register("new", { required: true })}
                    />
                    <FormErrorMessage error={errors.new} />
                </Form.Group>

                <Form.Group className={styles.group}>
                    <Form.Label>Confirm new password</Form.Label>
                    <Form.Control
                        type="password"
                        defaultValue={""}
                        id="confirmPassword"
                        autoComplete="off"
                        minLength={9}
                        {...register("confirm", {
                            required: true,
                            validate: validateConfirm,
                        })}
                    />
                    <FormErrorMessage error={errors.confirm} />
                </Form.Group>

                {!setPasswordState?.isLoading && (
                    <>
                        <Button type="submit">Set Password</Button>
                    </>
                )}
                {setPasswordState?.isLoading && (
                    <div className={styles.loginLoader}>
                        <div className={loaderStyles.Loader}>
                            <img
                                src="/ChompSpinner.svg"
                                alt="Setting password..."
                            />
                        </div>
                    </div>
                )}
                {passwordErrorMessage && (
                    <p className={formStyles.error}>{passwordErrorMessage}</p>
                )}
            </Form>
        </div>
    );
}
